import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';
export default function StaffEvaluationQuestionnaire(props) {
    const { pathname } = useLocation();
    // translation 
    const { t } = useTranslation("StaffEvaluationQuestionnaire");
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 
    const navigate = useNavigate();
    const changeLanguage = (lang, dir) => {
        document.getElementsByTagName("html")[0].setAttribute("lang", lang);
        document.getElementsByTagName("html")[0].setAttribute("dir", dir);
    }

    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [question3, setQuestion3] = useState('');
    const [question4, setQuestion4] = useState('');
    const [question5, setQuestion5] = useState('');
    const [errors, setErrors] = useState([]);

    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };

    const validateForm = () => {
        const errors = [];


        if (question1.trim() === '') {
            errors.push(t('Please provide a response for Question 1.'));
        }
        if (question2.trim() === '') {
            errors.push(t('Please provide a response for Question 2.'));
        }

        if (question3.trim() === '') {
            errors.push(t('Please provide a response for Question 3.'));
        }

        if (question4.trim() === '') {
            errors.push(t('Please provide a response for Question 4.'));
        }

        if (question5.trim() === '') {
            errors.push(t('Please provide a response for Question 5.'));
        }



        setErrors(errors);
        return errors;
    };
    async function submitForm(e) {
        e.preventDefault();
        const formErrors = validateForm();

        if (formErrors.length === 0) {
            const bodyFormData = new FormData();

            bodyFormData.append(t('What is the method you think is effective for you for infection control training ?'), question1);
            bodyFormData.append(t('What is the most critical part that needs improvement in IPC program ?'), question2);
            bodyFormData.append(t('Leadership support for IPC program and actions ?'), question3);
            bodyFormData.append(t('Compliance to IPC policies and procedure level ?'), question4);
            bodyFormData.append(t('IPC department communication and feedback with other department ?'), question5);


            const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/StaffEvaluationQuestionnaire`, bodyFormData)
                .then(response => {

                    if (response.data.Ecode === 200) {
                        console.log(response.data);




                        if (response.data.Edata.request_id > 0) {
                            setSubmitMsg(t('Thank you for completing the survey!'));

                        } else {
                            setSubmitMsg('Error !! please Check Your Data')

                        }

                        handleShow();

                    }

                })
                .catch(error => {
                    // Handle any errors
                    console.error(error);
                });

        }
    }
    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])
    return <>

        {i18n.language === 'en' ? <PageTitle title={t('Staff Evaluation')} anotherTitle={t('Questionnaire')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/staff-evaluation-questionnaire`} currentPage={t('Staff Evaluation Questionnaire')} /> : <PageTitle title={t('Questionnaire')} anotherTitle={t('Staff Evaluation')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/staff-evaluation-questionnaire`} currentPage={t('Staff Evaluation Questionnaire')} />}
        <div className="page-content">

            {/* <!--blog start--> */}

            <section>
                <div className="container">
                    <div className="row">
                        <div className="box-shadow">
                            <div className="post-comments">

                                <form onSubmit={submitForm}>
                                    <div className="messages"></div>
                                    <div className="row justify-content-between">
                                        <div className="col-md-7 p-5">
                                            <div className='row'>
                                                <div className="section-title col-md-12 text-center">

                                                    <h5 className='fw-bolder'>{t('Staff evaluation questionnaire')}</h5>
                                                    <h4 className='title'> <span>{t('IPC program 2024')}</span></h4>
                                                    <div className="col-md-12">
                                                        <center>
                                                            <div class="switch">
                                                                <input id="language-toggle" class="check-toggle check-toggle-round-flat" type="checkbox" onClick={() => {
                                                                    if (i18n.language === 'en') {
                                                                        handleLanguageChange('ar');
                                                                        document.getElementsByTagName("html")[0].setAttribute("lang", 'ar');

                                                                        document.getElementsByTagName("html")[0].setAttribute("dir", 'rtl');
                                                                        navigate(`/${Cookies.get('i18next')}/${pathname.slice(4)}`)
                                                                    } else {
                                                                        handleLanguageChange('en');
                                                                        document.getElementsByTagName("html")[0].setAttribute("lang", 'en');

                                                                        document.getElementsByTagName("html")[0].setAttribute("dir", 'ltr');

                                                                        navigate(`/${Cookies.get('i18next')}/${pathname.slice(4)}`);
                                                                    }
                                                                }} />
                                                                <label for="language-toggle"></label>
                                                                <span className={i18n.language === 'en' ? 'on' : 'off'}> English</span>
                                                                <span className={i18n.language === 'ar' ? 'on' : 'off'}>  العربية</span>
                                                            </div>
                                                        </center>
                                                    </div>
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes(t('Please provide a response for Question 1.')) ? 'is-invalid' : ''}`}>{t('1. What is the method you think is effective for you for infection control training ?')}</label>
                                                </div>
                                                <div class="form-group col-md-6">

                                                    <select className={`form-control ${errors.includes(t('Please provide a response for Question 1.')) ? 'is-invalid' : ''}`} value={question1}
                                                        onChange={(e) => setQuestion1(e.target.value)}>
                                                        <option value=''>{t('Select Answer')}</option>
                                                        <option value={t('Lectures')}>{t('Lectures')}</option>
                                                        <option value={t('On-job training while caring for patients')}>{t('On-job training while caring for patients')}</option>
                                                        <option value={t('Written work instructions')}>{t('Written work instructions')} </option>
                                                        <option value={t('Whatsapp groups and posts')}>{t('Whatsapp groups and posts')}</option>
                                                    </select>
                                                    {errors.includes(t('Please provide a response for Question 1.')) && <p className="ivaild-error">{t('Please provide a response for Question 1.')}</p>}

                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes(t('Please provide a response for Question 2.')) ? 'is-invalid' : ''}`}>{t('2. What is the most critical part need improvement in IPC program ?')}</label>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <select className={`form-control ${errors.includes(t('Please provide a response for Question 2.')) ? 'is-invalid' : ''}`} value={question2}
                                                        onChange={(e) => setQuestion2(e.target.value)}>
                                                        <option value=''>{t('Select Answer')}</option>
                                                        <option value={t('Training methodologies')}>{t('Training methodologies')}</option>
                                                        <option value={t('IPC bundles')}>{t('IPC bundles')}</option>
                                                        <option value={t('Vaccinations schedules')}>{t('Vaccinations schedules')}</option>
                                                        <option value={t('Waste segregation')}>{t('Waste segregation')}</option>
                                                    </select>
                                                    {errors.includes(t('Please provide a response for Question 2.')) && <p className="ivaild-error">{t('Please provide a response for Question 2.')}</p>}

                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes(t('Please provide a response for Question 3.')) ? 'is-invalid' : ''}`}>{t('3. Leadership support for IPC program and actions ?')}</label>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <select className={`form-control ${errors.includes(t('Please provide a response for Question 3.')) ? 'is-invalid' : ''}`} value={question3}
                                                        onChange={(e) => setQuestion3(e.target.value)}>
                                                        <option value=''>{t('Select Answer')}</option>
                                                        <option value={t('Good')}>{t('Good')}</option>
                                                        <option value={t('Fair')}>{t('Fair')}</option>
                                                        <option value={t('Not good')}>{t('Not good')}</option>
                                                        <option value={t('Don’t know')}>{t('Don’t know')}</option>
                                                    </select>
                                                    {errors.includes(t('Please provide a response for Question 3.')) && <p className="ivaild-error">{t('Please provide a response for Question 3.')}</p>}


                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes(t('Please provide a response for Question 4.')) ? 'is-invalid' : ''}`}>{t('4. Compliance to IPC policies and procedure level ?')}</label>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <select className={`form-control ${errors.includes(t('Please provide a response for Question 4.')) ? 'is-invalid' : ''}`} value={question4}
                                                        onChange={(e) => setQuestion4(e.target.value)}>
                                                        <option value=''>{t('Select Answer')}</option>
                                                        <option value={t('Very good')}>{t('Very good')}</option>
                                                        <option value={t('Fair')}>{t('Fair')}</option>
                                                        <option value={t('Neutral')}>{t('Neutral')}</option>
                                                        <option value={t('Not good')}>{t('Not good')}</option>
                                                    </select>
                                                    {errors.includes(t('Please provide a response for Question 4.')) && <p className="ivaild-error">{t('Please provide a response for Question 4.')}</p>}

                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`${errors.includes(t('Please provide a response for Question 5.')) ? 'is-invalid' : ''}`}>{t('5. IPC department communication and feedback with other department ?')}</label>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <select className={`form-control ${errors.includes(t('Please provide a response for Question 5.')) ? 'is-invalid' : ''}`} value={question5}
                                                        onChange={(e) => setQuestion5(e.target.value)}>
                                                        <option value=''>{t('Select Answer')}</option>
                                                        <option value={t('Very good')}>{t('Very good')}</option>
                                                        <option value={t('Fair')}>{t('Fair')}</option>
                                                        <option value={t('Neutral')}>{t('Neutral')}</option>
                                                        <option value={t('Not good')}>{t('Not good')}</option>
                                                    </select>
                                                    {errors.includes(t('Please provide a response for Question 5.')) && <p className="ivaild-error"> {t('Please provide a response for Question 5.')}</p>}

                                                </div>


                                                <div className="col-md-12">
                                                    <button className="btn btn-border btn-circle w-50" type='submit' ><span>{t('Submit')}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5" style={{ backgroundImage: `url('/images/forms/new-hire-paperwork_result.webp')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                            {/* <img src="images/forms/new-hire-paperwork_result.webp" alt="open day" /> */}
                                        </div>
                                        <div class="col-sm-12 text-center">
                                            <Modal   {...props}
                                                size="lg"
                                                style={customStyles}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered show={show} onHide={handleClose} className="top-0 ">

                                                <Modal.Header closeButton>
                                                    <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                    <Modal.Title style={{ color: "#4ab242" }}>{t('Staff Evaluation Questionnaire')}</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>
                                                    <div className="team-member text-center">

                                                        <div className="team-description pt-0">
                                                            <h5 className="my-5"><Link>{submitMsg}</Link></h5>

                                                        </div>



                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                        {t('Close')}
                                                    </Button>

                                                </Modal.Footer>

                                            </Modal>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
}
