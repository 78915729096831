import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageTitle from '../../../Utilites/PageTitle';
import Select from 'react-select';
import { GetDepartmentsOptionsContext } from '../../../../Context/GetDepartmentsOptions';
import axios from 'axios';
import Pagination from 'react-js-pagination';
import { LocalizationContext } from '../../../../Context/LocalizationContext';
import { useTranslation } from 'react-i18next';
// import { withRouter } from 'react-router';

export default function Specialists() {
    // translation 
    const { t } = useTranslation("CairoSpecialists");
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 
    let { GetDepartmentsSelectOptions, DepartmentsOptions } = useContext(GetDepartmentsOptionsContext);

    const { pathname } = useLocation();

    // const [allSpecialities, setAllSpecialities] = useState([
    //     { icon: '/images/service/anesthesia.png', img: '/images/service/ANESTHESIA.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Anesthesia', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/heart-small.png', img: '/images/service/cardiology.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/cardiology`, name: 'Cardiology', desc: 'To diagnose and treat cardiac conditions efficiently, SGH-Cairo offers the most comprehensive range of state-of-art ' },
    //     { icon: '/images/service/pulmonology.png', img: '/images/service/Chest.webp', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/chest-and-pulmonology`, name: 'Chest and Pulmonology', desc: 'At Saudi German Hospital Cairo, we recognize the paramount importance of breathing in sustaining human life.' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Cardiothoracic Surgery', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Dental and Maxillofacial', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Dermatology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Diabetes and Endocrinologys', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'General surgery', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Gastroenterology and Hepatology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Obstetrics and Gynecology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Internal Medicine', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Kidney Transplantation', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Laboratory', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Liver Transplantation', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Neurology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Nephrology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Ophthalmology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Orthopedic', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Pain Management', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Pediatric', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Physiotherapy Department', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Psychiatry', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Radiology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Radiotherapy', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Urology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/anesthesia`, name: 'Vascular Surgery', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    // ])

    const [visible, setVisible] = useState(6);
    const options = {
        margin: 30,
        responsiveClass: true,
        autoplay: true,
        smartSpeed: 1000,

    }

    function loadMore() {
        setVisible(visible + 6);
    }

    // const [searchData, setSearchData] = useState([]);
    // const [search, setSearch] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [searchResults, setSearchResults] = useState([]);

    const handleInputChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        performSearch(selectedOption);
    };

    const performSearch = (selectedOption) => {
        if (selectedOption) {
            const filteredResults = allSpecialities.filter(
                (specialities) => specialities.name === selectedOption.label
            );
            setSearchResults(filteredResults);
            document.querySelector('.specialities').classList.add('d-none');
        } else {
            setSearchResults([]);
            document.querySelector('.result').classList.add('d-none');

        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Backspace') {
            setSelectedOption(null);
            setSearchResults([]);
            document.querySelector('.specialities').classList.remove('d-none');

        }
    };
    // function getSearchData(e) {
    //     e.preventDefault()

    //     if (search === allSpecialities.name) {

    //         setAllSpecialities([{ name: 'assa', desc: 'ddsdsdddd' }])



    //     } else {
    //         setAllSpecialities([
    //             { name: 'Anesthesia', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Cardiology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Chest and Pulmonology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Cardiothoracic Surgery', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Dental and Maxillofacial', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Dermatology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Diabetes and Endocrinologys', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'General surgery', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Gastroenterology and Hepatology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Obstetrics and Gynecology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Internal Medicine', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Kidney Transplantation', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Laboratory', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Liver Transplantation', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Neurology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Nephrology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Ophthalmology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Orthopedic', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Pain Management', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Pediatric', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Physiotherapy Department', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Psychiatry', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Radiology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Radiotherapy', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Urology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //             { name: 'Vascular Surgery', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //         ]);
    //     }

    // }
    useEffect(() => {
        // getSearchData();
        GetDepartmentsSelectOptions();
    }, [])


    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }


    let [lastPageOfSearch, setLastPageOfSearch] = useState()

    let [perPage, setPerPage] = useState();
    let [total, setTotal] = useState();
    // const { pageNumber } = useParams();

    let [currentPage, setCurrentPage] = useState(1)
    const location = useLocation();
    const navigate = useNavigate();

    // Parse query parameter `page` from URL
    const queryParams = new URLSearchParams(location.search);
    const pageFromUrl = queryParams.get('page');
    useEffect(() => {
        if (pageFromUrl) {
            setCurrentPage(Number(pageFromUrl)); // Update currentPage based on query parameter
        }
    }, [location.search]); // Re-run the effect if the URL changes


    const handlePageChange = (pageNumber) => {
        // const pageNumber = e;

        setCurrentPage(pageNumber);
        navigate(`?page=${pageNumber}`); // Update URL to reflect page change
        scrollToTop();
    };

    // get department
    const [allSpecialities, setAllSpecialities] = useState([]);
    async function getSpecialities(hospital_id, currentPage) {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        // bodyFormData.append('pin', pinNumInp);
        // bodyFormData.append('phone', phoneNumInp);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
        }

        const response = await axios.get(`https://sgheg.com/Sadm25/api/department/list/${Cookies.get('i18next')}?hospital_id=${hospital_id}&page=${currentPage}`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    setAllSpecialities(response.data.Edata.data);
                    setPerPage(response.data.Edata.per_page);
                    setTotal(response.data.Edata.total);
                    setLastPageOfSearch(response.data.Edata.last_page)
                    console.log('all speciality ' + allSpecialities);


                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }
    useEffect(() => {
        getSpecialities('1', currentPage);
    }, [currentPage])
    return <>
        {/* <!--page title start--> */}
        {i18n.language === 'en' ? <PageTitle title={t('Our')} anotherTitle={t('Specialties')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo`} prevPage={t('Cairo')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties`} currentPage={t('Our Specialties')} /> : <PageTitle title={t('Our Specialties')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo`} prevPage={t('Cairo')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties`} currentPage={t('Our Specialties')} />}
        {/* <!--page title end--> */}

        <div class="page-content">

            {/* <!--featured start--> */}

            {/* <!--pricing table style2 start--> */}

            <section>
                <div class="container">
                    <div className="row specialities justify-content-center gy-3">
                        {allSpecialities.map((speciality, index) => (
                            <div className='col-lg-4 col-md-4' key={index}>
                                <div class="price-table style-2 active" data-bg-img='/images/cairo/specialitites/Specialties-for-all---min.webp' style={{ backgroundImage: `url('/images/cairo/specialitites/Specialties-for-all---min.webp')` }}>
                                    <div class="price-inner bg-transparent">
                                        <div class="featured-item text-center">

                                            {speciality.icon ? <img src={speciality.icon} alt="icon" height='65px' /> : <img src='/images/service/anesthesia.png' alt="icon" height='65px' />}

                                        </div>
                                        <div class="price-header">
                                            <h4 class="price-title">{speciality.famous_name}</h4>
                                        </div>


                                        <div class="price-list" style={{ aspectRatio: 5 / 2 }} >
                                            <ul class="list-unstyled" >
                                                <p dangerouslySetInnerHTML={{ __html: speciality.brief.replace(/^(.{200}\S*).*$/, '$1') }}></p>
                                            </ul>
                                        </div>
                                        <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialties/${speciality.famous_name}/${speciality.id}`} className='btn btn-block btn-theme mt-4'>
                                            <span>{t('Read More')}</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>


                    {lastPageOfSearch > 1 ? <>
                        <div className="row justify-content-center text-center mt-3" id='pagination'>
                            < Pagination
                                activePage={currentPage}
                                itemsCountPerPage={perPage}
                                totalItemsCount={total}
                                pageRangeDisplayed={5}
                                hideLastPages={true}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div></> : ''}
                </div>
            </section >

            {/* <!--pricing table style2 end--> */}
            {/* <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/specialists/anesthesia`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i className='flaticon-stethoscope'></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Anesthesia</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Cardiology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-drug"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Chest and Pulmonology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-blood-donation"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Cardiothoracic Surgery</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Dental and Maxillofacial</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-ambulance"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Dermatology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i className='flaticon-stethoscope'></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Diabetes and Endocrinology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>General surgery</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Gastroenterology and Hepatology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-ambulance"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Obstetrics and Gynecology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i className='flaticon-stethoscope'></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Internal Medicine</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Kidney Transplantation</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Laboratory</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-ambulance"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Liver Transplantation</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i className='flaticon-stethoscope'></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Neurology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Nephrology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Ophthalmology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-ambulance"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Orthopedic</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i className='flaticon-stethoscope'></i>
                                    </div>hfhfhf
                                    <div class="featured-title text-uppercase">
                                        <h5>Pain Management</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Pediatric</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Physiotherapy Department
                                        </h5>
                                    </div>
                               
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-ambulance"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Psychiatry</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i className='flaticon-stethoscope'></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Radiology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Radiotherapy</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Urology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-ambulance"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Vascular Surgery
                                        </h5>
                                    </div>
                                 

                                </div>
                            </Link>
                        </div>

                    </div>

                </div>
            </section> */}

            {/* <!--featured end--> */}
        </div >
    </>
}
