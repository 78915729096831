import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import { LocalizationContext } from '../../Context/LocalizationContext';
// import { useTranslation } from 'react-i18next';

export default function StakeholderSurvey(props) {
    // let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // const { t } = useTranslation("PatientSatisfactionQuestionnaire");
    // const { pathname } = useLocation();
    // const navigate = useNavigate();


    const [relationship, setRelationship] = useState('');
    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [question3, setQuestion3] = useState('');
    const [question4, setQuestion4] = useState('');
    const [question5, setQuestion5] = useState('');
    const [question6, setQuestion6] = useState('');
    const [question7, setQuestion7] = useState('');

    const [errors, setErrors] = useState([]);



    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');
    // const [messageForm, setMessageForm] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    const validateForm = () => {
        const errors = [];

        if (!relationship) {
            errors.push('Please specify your relationship with Saudi German Hospital – Cairo.');
        }

        if (!question1) {
            errors.push('Kindly share your thoughts on whether the mission and vision statements align with your healthcare needs and expectations.');
        }
        if (!question2) {
            errors.push("Please assess the hospital's scope of service and provide details on any improvements needed.");
        }
        if (!question3) {
            errors.push("Your feedback on the ease of communication with the hospital's leadership and satisfaction with current communication channels would be appreciated.");
        }
        if (!question4) {
            errors.push('Please provide your insight on the strength of the leadership.');
        }
        if (!question5) {
            errors.push('Kindly share your perspective on the operational efficiency of the hospital.');
        }
        if (!question6) {
            errors.push('Please assess the level of responsibility you believe the hospital demonstrates towards its stakeholders.');
        }
        if (!question7) {
            errors.push('Please provide your recommendations and suggestions for improvement.');
        }
        setErrors(errors);
        return errors;
    };

    async function submitForm(e) {
        e.preventDefault();

        const formErrors = validateForm();

        if (formErrors.length === 0) {
            // Proceed with form submission
            const bodyFormData = new FormData();

            bodyFormData.append('relationship', relationship);
            bodyFormData.append('Mission and Vision', question1);
            bodyFormData.append('Scope of Service', question2);
            bodyFormData.append('Communication', question3);
            bodyFormData.append('Do you think that the hospital has a good strong leadership?', question4);
            bodyFormData.append('Do you think that the hospital is well and efficiently run?', question5);
            bodyFormData.append('Overall, how responsible do you think the hospital is towards all its stakeholders?', question6);
            bodyFormData.append('Recommendations and Suggestions', question7);

            try {
                const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/StakeholderSurvey`, bodyFormData);
                if (response.data.Ecode === 200) {
                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg('Thank you for Registration!');
                        // setMessageForm('We value your feedback and take all customer complaints seriously. Our team will review your request');
                        setRelationship('');
                        setQuestion1('');
                        setQuestion2('');
                        setQuestion3('');
                        setQuestion4('');
                        setQuestion5('');
                        setQuestion6('');
                        setQuestion7('');


                    } else {
                        setSubmitMsg('Error !! please Check Your Data');
                    }
                    handleShow();
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])
    return <>
        {/* <PageTitle title={t('Patient Satisfaction Questionnaire')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-satisfaction-questionnaire`} currentPage={t('Patient Satisfaction Questionnaire')} /> */}
        {/* <!--page title end--> */}
        <div className="page-content">

            {/* <!--blog start--> */}

            <section className='p-0'>
                <div className="container">
                    <div className="row">

                        <div className="box-shadow">
                            <div className="post-comments">

                                <form onSubmit={submitForm}>
                                    <div className="messages"></div>
                                    <div className="row justify-content-center  ">
                                        <div className="col-md-12 text-center">
                                            <img src="/images/forms/composite-image-hands-holding-up-survey-against-white-room-large-window-overlooking-city-52705932.webp" className='img-fluid' alt="open day" />
                                        </div>

                                        <div className="col-md-10 p-5 order-3 ">
                                            <div className='row justify-content-center align-items-center'>
                                                {/* <div class="section-title mb-0">
                                                    <h2 class="title mb-3 text-center">complete <span>Form</span></h2>

                                                </div> */}
                                                <div class="form-group col-md-12">
                                                    <h5 className='fw-bolder fs-6 mb-0'>My relationship with Saudi German Hospital – Cairo is: </h5>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <select onChange={(e) => setRelationship(e.target.value)} value={relationship}>
                                                        <option value="">Choose One</option>
                                                        <option value="Supplier">Supplier</option>
                                                        <option value="Contractor">Contractor</option>
                                                        <option value="Civil community organization">Civil community organization</option>
                                                        <option value="Referral organization">Referral organization</option>
                                                        <option value="Syndicate/Association">Syndicate/Association</option>
                                                        <option value="Insurance Company">Insurance Company</option>
                                                        <option value="Others">Others</option>

                                                    </select><br />
                                                    {errors.includes('Please specify your relationship with Saudi German Hospital – Cairo.') && (
                                                        <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">Please specify your relationship with Saudi German Hospital – Cairo.</div>
                                                        </>)}
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label class="form-label fs-5 fw-bolder pb-3 text-theme">We would like your feedback on the following items at Saudi German Hospital – Cairo:</label>
                                                    <h5 className='fs-6 fw-bolder'>1.	Mission and Vision </h5>
                                                    <label class="form-label fs-6">a.	Do you think that the mission and vision statements are coping with your current and future needs and expectations for healthcare services? </label>

                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques1'>

                                                        <input
                                                            type="radio" className='me-2' id='ques1'
                                                            value='Strongly Agree'
                                                            checked={question1 === 'Strongly Agree'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        Strongly Agree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques2'>

                                                        <input className='me-2' id='ques2'
                                                            type="radio"
                                                            value='Agree'
                                                            checked={question1 === 'Agree'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        Agree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques3'>

                                                        <input className='me-2' id='ques3'
                                                            type="radio"
                                                            value='Neutral'
                                                            checked={question1 === 'Neutral'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        Neutral
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques4'>

                                                        <input className='me-2' id='ques4'
                                                            type="radio"
                                                            value='Disagree'
                                                            checked={question1 === 'Disagree'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        Disagree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques5'>

                                                        <input className='me-2' id='ques5'
                                                            type="radio"
                                                            value='Strongly Disagree'
                                                            checked={question1 === 'Strongly Disagree'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        Strongly Disagree
                                                    </label>
                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes('Kindly share your thoughts on whether the mission and vision statements align with your healthcare needs and expectations.') && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">Kindly share your thoughts on whether the mission and vision statements align with your healthcare needs and expectations.</div>
                                                            </>)}
                                                    </div>
                                                </div>
                                                {/* <div class="form-group col-md-12">

                                                    {errors.includes(t('Kindly choose a One.')) && (
                                                        <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">{t('Kindly choose a One.')}</div>
                                                        </>)}</div> */}
                                                <div className="form-group col-md-12">
                                                    <h5 className='fs-6 fw-bolder'>2.	Scope of Service </h5>
                                                    <label class="form-label fs-6">a.	Do you think that the hospital has a good, effective scope of service? If the answer is no, what could be added or removed?  </label>

                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques6'>

                                                        <input
                                                            type="radio" className='me-2' id='ques6'
                                                            value='Strongly Agree'
                                                            checked={question2 === 'Strongly Agree'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        Strongly Agree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques7'>

                                                        <input className='me-2' id='ques7'
                                                            type="radio"
                                                            value='Agree'
                                                            checked={question2 === 'Agree'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        Agree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques8'>

                                                        <input className='me-2' id='ques8'
                                                            type="radio"
                                                            value='Neutral'
                                                            checked={question2 === 'Neutral'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        Neutral
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques9'>

                                                        <input className='me-2' id='ques9'
                                                            type="radio"
                                                            value='Disagree'
                                                            checked={question2 === 'Disagree'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        Disagree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques10'>

                                                        <input className='me-2' id='ques10'
                                                            type="radio"
                                                            value='Strongly Disagree'
                                                            checked={question2 === 'Strongly Disagree'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        Strongly Disagree
                                                    </label>
                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes("Please assess the hospital's scope of service and provide details on any improvements needed.") && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">Please assess the hospital's scope of service and provide details on any improvements needed.</div>
                                                            </>)}
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <h5 className='fs-6 fw-bolder'>3.	Communication </h5>
                                                    <label class="form-label fs-6">a.	Are you satisfied of the current communication channels? In particular – Is it easy to communication with the hospital’s leadership on particular issues or make your feelings known to them? </label>

                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques11'>

                                                        <input
                                                            type="radio" className='me-2' id='ques11'
                                                            value='Strongly Agree'
                                                            checked={question3 === 'Strongly Agree'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        Strongly Agree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques12'>

                                                        <input className='me-2' id='ques12'
                                                            type="radio"
                                                            value='Agree'
                                                            checked={question3 === 'Agree'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        Agree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques13'>

                                                        <input className='me-2' id='ques13'
                                                            type="radio"
                                                            value='Neutral'
                                                            checked={question3 === 'Neutral'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        Neutral
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques14'>

                                                        <input className='me-2' id='ques14'
                                                            type="radio"
                                                            value='Disagree'
                                                            checked={question3 === 'Disagree'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        Disagree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques15'>

                                                        <input className='me-2' id='ques15'
                                                            type="radio"
                                                            value='Strongly Disagree'
                                                            checked={question3 === 'Strongly Disagree'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        Strongly Disagree
                                                    </label>
                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes("Your feedback on the ease of communication with the hospital's leadership and satisfaction with current communication channels would be appreciated.") && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">Your feedback on the ease of communication with the hospital's leadership and satisfaction with current communication channels would be appreciated.</div>
                                                            </>)}
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <h5 className='fs-6 fw-bolder'>4.	Organizational Leaders’ Performance </h5>
                                                    <label class="form-label fs-6">a.	Do you think that the hospital has a good strong leadership? </label>

                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques16'>

                                                        <input
                                                            type="radio" className='me-2' id='ques16'
                                                            value='Strongly Agree'
                                                            checked={question4 === 'Strongly Agree'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        Strongly Agree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques17'>

                                                        <input className='me-2' id='ques17'
                                                            type="radio"
                                                            value='Agree'
                                                            checked={question4 === 'Agree'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        Agree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques18'>

                                                        <input className='me-2' id='ques18'
                                                            type="radio"
                                                            value='Neutral'
                                                            checked={question4 === 'Neutral'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        Neutral
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques19'>

                                                        <input className='me-2' id='ques19'
                                                            type="radio"
                                                            value='Disagree'
                                                            checked={question4 === 'Disagree'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        Disagree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques20'>

                                                        <input className='me-2' id='ques20'
                                                            type="radio"
                                                            value='Strongly Disagree'
                                                            checked={question4 === 'Strongly Disagree'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        Strongly Disagree
                                                    </label>

                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes('Please provide your insight on the strength of the leadership.') && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">Please provide your insight on the strength of the leadership.</div>
                                                            </>)}
                                                    </div>
                                                </div>

                                                <div className="form-group col-md-12">

                                                    <label class="form-label fs-6">b.	Do you think that the hospital is well and efficiently run? </label>

                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques21'>

                                                        <input
                                                            type="radio" className='me-2' id='ques21'
                                                            value='Strongly Agree'
                                                            checked={question5 === 'Strongly Agree'}
                                                            onChange={(e) => setQuestion5(e.target.value)}
                                                        />
                                                        Strongly Agree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques22'>

                                                        <input className='me-2' id='ques22'
                                                            type="radio"
                                                            value='Agree'
                                                            checked={question5 === 'Agree'}
                                                            onChange={(e) => setQuestion5(e.target.value)}
                                                        />
                                                        Agree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques23'>

                                                        <input className='me-2' id='ques23'
                                                            type="radio"
                                                            value='Neutral'
                                                            checked={question5 === 'Neutral'}
                                                            onChange={(e) => setQuestion5(e.target.value)}
                                                        />
                                                        Neutral
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques24'>

                                                        <input className='me-2' id='ques24'
                                                            type="radio"
                                                            value='Disagree'
                                                            checked={question5 === 'Disagree'}
                                                            onChange={(e) => setQuestion5(e.target.value)}
                                                        />
                                                        Disagree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques25'>

                                                        <input className='me-2' id='ques25'
                                                            type="radio"
                                                            value='Strongly Disagree'
                                                            checked={question5 === 'Strongly Disagree'}
                                                            onChange={(e) => setQuestion5(e.target.value)}
                                                        />
                                                        Strongly Disagree
                                                    </label>

                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes('Kindly share your perspective on the operational efficiency of the hospital.') && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">Kindly share your perspective on the operational efficiency of the hospital.</div>
                                                            </>)}
                                                    </div>
                                                </div>

                                                <div className="form-group col-md-12">

                                                    <label class="form-label fs-6">c.	Overall, how responsible do you think the hospital is towards all its stakeholders? </label>

                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques26'>

                                                        <input
                                                            type="radio" className='me-2' id='ques26'
                                                            value='Strongly Agree'
                                                            checked={question6 === 'Strongly Agree'}
                                                            onChange={(e) => setQuestion6(e.target.value)}
                                                        />
                                                        Strongly Agree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques27'>

                                                        <input className='me-2' id='ques27'
                                                            type="radio"
                                                            value='Agree'
                                                            checked={question6 === 'Agree'}
                                                            onChange={(e) => setQuestion6(e.target.value)}
                                                        />
                                                        Agree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques28'>

                                                        <input className='me-2' id='ques28'
                                                            type="radio"
                                                            value='Neutral'
                                                            checked={question6 === 'Neutral'}
                                                            onChange={(e) => setQuestion6(e.target.value)}
                                                        />
                                                        Neutral
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques29'>

                                                        <input className='me-2' id='ques29'
                                                            type="radio"
                                                            value='Disagree'
                                                            checked={question6 === 'Disagree'}
                                                            onChange={(e) => setQuestion6(e.target.value)}
                                                        />
                                                        Disagree
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques30'>

                                                        <input className='me-2' id='ques30'
                                                            type="radio"
                                                            value='Strongly Disagree'
                                                            checked={question6 === 'Strongly Disagree'}
                                                            onChange={(e) => setQuestion6(e.target.value)}
                                                        />
                                                        Strongly Disagree
                                                    </label>

                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes('Please assess the level of responsibility you believe the hospital demonstrates towards its stakeholders.') && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">Please assess the level of responsibility you believe the hospital demonstrates towards its stakeholders.</div>
                                                            </>)}
                                                    </div>
                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className={`fs-6 fw-bolder ${errors.includes('Please provide your recommendations and suggestions for improvement.') ? 'is-invalid' : ''}`}>5.	Recommendations and Suggestions </label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <div class="form-group col-md-12">
                                                        <textarea class="form-control h-auto" value={question7} onChange={(e) => setQuestion7(e.target.value)}></textarea>
                                                        <div class="form-group col-md-12 mb-0 mt-3">

                                                            {errors.includes('Please provide your recommendations and suggestions for improvement.') && (
                                                                <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">Please provide your recommendations and suggestions for improvement.</div>
                                                                </>)}
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-4">
                                                    <button className="btn btn-border btn-circle w-100" type='submit'><span>Submit</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="col-md-7 order-1 order-sm-2" style={{ backgroundImage: `url('/images/forms/diabetes-health-awareness-1.png')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                            <img src="/images/forms/diabetes-health-awareness-2.png" alt="patient survey 2024" className='d-block d-md-none img-fluid' style={{ backgroundSize: 'contain', backgroundColor: '#61882b', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }} />
                                        </div> */}
                                        <div class="col-sm-12 text-center">
                                            <Modal   {...props}
                                                size="lg"
                                                style={customStyles}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered show={show} onHide={handleClose} className="top-0 ">

                                                <Modal.Header closeButton>
                                                    <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                    <Modal.Title style={{ color: "#4ab242" }}>Your Message</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>
                                                    <div className="team-member text-center">

                                                        <div className="team-description pt-0">
                                                            <h5 className="my-5"><Link>{submitMsg}</Link></h5>
                                                            {/* <p className='lead'>{messageForm}</p> */}
                                                        </div>



                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                        Close
                                                    </Button>

                                                </Modal.Footer>

                                            </Modal>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
        </div >
    </>
}
