import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageTitle from '../../../Utilites/PageTitle';
import axios from 'axios';
import Pagination from 'react-js-pagination';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../../../Context/LocalizationContext';

export default function OurServices() {
    // translation 
    const { t } = useTranslation("AlexServices");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation
    const { pathname } = useLocation();

    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    // pagination
    let [lastPageOfSearch, setLastPageOfSearch] = useState()

    let [perPage, setPerPage] = useState();
    let [total, setTotal] = useState();
    let [currentPage, setCurrentPage] = useState(1);
    const location = useLocation();
    const navigate = useNavigate();

    // Parse query parameter `page` from URL
    const queryParams = new URLSearchParams(location.search);
    const pageFromUrl = queryParams.get('page');
    useEffect(() => {
        if (pageFromUrl) {
            setCurrentPage(Number(pageFromUrl)); // Update currentPage based on query parameter
        }
    }, [location.search]); // Re-run the effect if the URL changes


    const handlePageChange = (pageNumber) => {
        // const pageNumber = e;

        setCurrentPage(pageNumber);
        navigate(`?page=${pageNumber}`); // Update URL to reflect page change
        scrollToTop();
    };


    // get services

    const [allServices, SetAllServices] = useState([]);
    async function getServices(hospital_id, currentPage) {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        // bodyFormData.append('pin', pinNumInp);
        // bodyFormData.append('phone', phoneNumInp);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
        }

        const response = await axios.get(`https://my-health.sghcairo.net/sgh_api/mayo/public/service/list/${Cookies.get('i18next')}?hospital_id=${hospital_id}&page=${currentPage}`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    SetAllServices(response.data.Edata.data);
                    setPerPage(response.data.Edata.per_page);
                    setTotal(response.data.Edata.total);
                    setLastPageOfSearch(response.data.Edata.last_page)
                    console.log('all services ' + response.data.Edata.data);


                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }
    useEffect(() => {
        getServices('2', currentPage);
    }, [currentPage])
    return <>
        {/* <!--page title start--> */}
        {i18n.language === 'en' ? <PageTitle title={t('Our')} anotherTitle={t('Services')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/services`} currentPage={t('Services')} />
            : <PageTitle title={t('Services')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/services`} currentPage={t('Services')} />}
        {/* <!--page title end--> */}


        {/* <!--body content start--> */}


        <div className="page-content">

            {/* <!--service start--> */}

            <section class="text-center">
                <div class="container">
                    <div class="row gy-3">
                        {allServices?.map((allService, index) => <>
                            <div class="service-item col-lg-4" key={index}>
                                <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/services/${allService.famous_name}/${allService.id}`}>
                                    <div class="service-images">
                                        {allService.image ? <img class="img-fluid" src={`${allService.image}`} alt="" /> : <img class="img-fluid" src="/images/service/01.jpg" alt="" />}
                                        <div class="service-icon">
                                            {allService.icon ? <img src={`${allService.icon}`} class="flaticon-doctor"></img> : <i class="flaticon-doctor"></i>}
                                        </div>
                                    </div>
                                    <h4>{allService.famous_name}</h4>
                                    <div class="service-description text-black">
                                        <p dangerouslySetInnerHTML={{ __html: allService.shortnote.replace(/^(.{200}\S*).*$/, '$1') }}></p>
                                        <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/services/${allService.famous_name}/${allService.id}`}>{t('Read More')}
                                            {i18n.language === 'en' ? <i class="ms-2 fas fa-long-arrow-alt-right"></i> : <i class="ms-2 fas fa-long-arrow-alt-left"></i>}
                                        </Link>
                                    </div>
                                </Link>
                            </div>
                        </>)}

                        {lastPageOfSearch > 1 ? <>
                            < div className="row justify-content-center text-center mt-3" id='pagination'>
                                < Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={perPage}
                                    totalItemsCount={total}
                                    pageRangeDisplayed={5}
                                    hideLastPages={true}
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </div></> : ''}
                        {/* <div class="service-item col-lg-4">
                            <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/services/physiotherapy-department`}>
                                <div class="service-images">
                                    <img class="img-fluid" src="/images/service/02.jpg" alt="" />
                                    <div class="service-icon"> <i class="flaticon-health"></i>
                                    </div>
                                </div>
                                <h4>Physiotherapy Department</h4>
                                <div class="service-description text-black">
                                    <p>Our Physiotherapy and ilitation department is a global leader in providing the most advanced evidence-based practice protocols and cutting-edge services that enable our patients to regain their functional abilities and get back to daily activities without pain or functional constraints.</p> <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/services/physiotherapy-department`}>Read More <i class="ms-2 fas fa-long-arrow-alt-right"></i></Link>
                                </div>
                            </Link>
                        </div> */}




                    </div>
                </div>
            </section>



        </div>
    </>
}
