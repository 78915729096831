import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import { LocalizationContext } from '../../Context/LocalizationContext';
// import { useTranslation } from 'react-i18next';

export default function CssdSurvey(props) {
    // let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // const { t } = useTranslation("PatientSatisfactionQuestionnaire");
    // const { pathname } = useLocation();
    // const navigate = useNavigate();


    const [department, setDepartment] = useState('');
    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [question3, setQuestion3] = useState('');
    const [question4, setQuestion4] = useState('');
    const [question5, setQuestion5] = useState('');


    const [errors, setErrors] = useState([]);



    //  submit 
    const [submitMsg, setSubmitMsg] = useState('');
    // const [messageForm, setMessageForm] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    const validateForm = () => {
        const errors = [];

        if (!department) {
            errors.push('Kindly specify your department');
        }

        if (!question1) {
            errors.push('Share your satisfaction level regarding communication with CSSD staff.');
        }
        if (!question2) {
            errors.push("Provide your satisfaction rating for equipment sterilization quality in CSSD.");
        }
        if (!question3) {
            errors.push("Rate your satisfaction with the timeliness of CSSD services.");
        }
        if (!question4) {
            errors.push('Rate your satisfaction with the availability of services in CSSD.');
        }
        if (!question5) {
            errors.push('Please provide your recommendations and suggestions for improvement.');
        }

        setErrors(errors);
        return errors;
    };

    async function submitForm(e) {
        e.preventDefault();

        const formErrors = validateForm();

        if (formErrors.length === 0) {
            // Proceed with form submission
            const bodyFormData = new FormData();

            bodyFormData.append('Department', department);
            bodyFormData.append('Satisfaction about communication with CSSD staff?', question1);
            bodyFormData.append('Satisfaction about the quality of equipment’s sterilization inside CSSD?', question2);
            bodyFormData.append('Satisfaction about timeliness of CSSD services?', question3);
            bodyFormData.append('Satisfaction about availability of all services inside CSSD?', question4);
            bodyFormData.append('Suggestions & Comments', question5);


            try {
                const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/CssdSurvey`, bodyFormData);
                if (response.data.Ecode === 200) {
                    if (response.data.Edata.request_id > 0) {
                        setSubmitMsg('Thank you for Registration!');
                        // setMessageForm('We value your feedback and take all customer complaints seriously. Our team will review your request');
                        setDepartment('');
                        setQuestion1('');
                        setQuestion2('');
                        setQuestion3('');
                        setQuestion4('');
                        setQuestion5('');



                    } else {
                        setSubmitMsg('Error !! please Check Your Data');
                    }
                    handleShow();
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (submitMsg.length > 0) {
            handleShow();

        }

    }, [submitMsg])
    return <>
        {/* <PageTitle title={t('Patient Satisfaction Questionnaire')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-satisfaction-questionnaire`} currentPage={t('Patient Satisfaction Questionnaire')} /> */}
        {/* <!--page title end--> */}
        <div className="page-content">

            {/* <!--blog start--> */}

            <section className='p-0'>
                <div className="container">
                    <div className="row">

                        <div className="box-shadow">
                            <div className="post-comments">

                                <form onSubmit={submitForm}>
                                    <div className="messages"></div>
                                    <div className="row justify-content-center">
                                        <div className="col-md-12 text-center pt-5">
                                            <h5 className='text-theme fw-bolder'>WELCOME TO THE CSSD QUESTIONNAIRE</h5>
                                            <p>By completing this survey, you can help Central Sterile Services Department focus its efforts and resources effectively while recognizing and prioritizing your needs. <br /> This survey is confidential and is aims to evaluate the performance of the CSSD in (Communication, service quality, and availability)
                                                <br /> Thank you, in advance, for your contribution to this survey and your support is appreciated.
                                            </p>
                                            {/* <img src="/images/forms/Earned-Media-vs-Paid-Media-The-PHA-Group.jpg" className='img-fluid' alt="open day" /> */}
                                        </div>

                                        <div className="col-md-10 p-5 order-3 ">
                                            <div className='row justify-content-center align-items-center'>
                                                {/* <div class="section-title mb-0">
                                                    <h2 class="title mb-3 text-center">complete <span>Form</span></h2>

                                                </div> */}
                                                <div class="form-group col-md-12">

                                                    <h6 className='fw-bolder fs-6 text-theme'>Please select the option that best reflects your satisfaction :</h6>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='fs-6 fw-bolder' >1.	Please indicate your department? </label>

                                                    <input className='form-control w-50' onChange={(e) => setDepartment(e.target.value)} value={department} />

                                                    {errors.includes('Kindly specify your department') && (
                                                        <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">Kindly specify your department</div>
                                                        </>)}
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <h5 className='fs-6 fw-bolder'>2.	Satisfaction about communication with CSSD staff?</h5>

                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques1'>

                                                        <input
                                                            type="radio" className='me-2' id='ques1'
                                                            value='Very Poor'
                                                            checked={question1 === 'Very Poor'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        Very Poor
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques2'>

                                                        <input className='me-2' id='ques2'
                                                            type="radio"
                                                            value='Poor'
                                                            checked={question1 === 'Poor'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        Poor
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques3'>

                                                        <input className='me-2' id='ques3'
                                                            type="radio"
                                                            value='Fair'
                                                            checked={question1 === 'Fair'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        Fair
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques4'>

                                                        <input className='me-2' id='ques4'
                                                            type="radio"
                                                            value='Good'
                                                            checked={question1 === 'Good'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        Good
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques5'>

                                                        <input className='me-2' id='ques5'
                                                            type="radio"
                                                            value='Excellent'
                                                            checked={question1 === 'Excellent'}
                                                            onChange={(e) => setQuestion1(e.target.value)}
                                                        />
                                                        Excellent
                                                    </label>
                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes('Share your satisfaction level regarding communication with CSSD staff.') && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">Share your satisfaction level regarding communication with CSSD staff.</div>
                                                            </>)}
                                                    </div>
                                                </div>
                                                {/* <div class="form-group col-md-12">

                                                    {errors.includes(t('Kindly choose a One.')) && (
                                                        <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">{t('Kindly choose a One.')}</div>
                                                        </>)}</div> */}
                                                <div className="form-group col-md-12">
                                                    <h5 className='fs-6 fw-bolder'>3.	Satisfaction about the quality of equipment’s sterilization inside CSSD?</h5>

                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques6'>

                                                        <input
                                                            type="radio" className='me-2' id='ques6'
                                                            value='Very Poor'
                                                            checked={question2 === 'Very Poor'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        Very Poor
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques7'>

                                                        <input className='me-2' id='ques7'
                                                            type="radio"
                                                            value='Poor'
                                                            checked={question2 === 'Poor'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        Poor
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques8'>

                                                        <input className='me-2' id='ques8'
                                                            type="radio"
                                                            value='Fair'
                                                            checked={question2 === 'Fair'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        Fair
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques9'>

                                                        <input className='me-2' id='ques9'
                                                            type="radio"
                                                            value='Good'
                                                            checked={question2 === 'Good'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        Good
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques10'>

                                                        <input className='me-2' id='ques10'
                                                            type="radio"
                                                            value='Excellent'
                                                            checked={question2 === 'Excellent'}
                                                            onChange={(e) => setQuestion2(e.target.value)}
                                                        />
                                                        Excellent
                                                    </label>
                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes("Provide your satisfaction rating for equipment sterilization quality in CSSD.") && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">Provide your satisfaction rating for equipment sterilization quality in CSSD.</div>
                                                            </>)}
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <h5 className='fs-6 fw-bolder'>4.	Satisfaction about timeliness of CSSD services?</h5>

                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques11'>

                                                        <input
                                                            type="radio" className='me-2' id='ques11'
                                                            value='Very Poor'
                                                            checked={question3 === 'Very Poor'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        Very Poor
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques12'>

                                                        <input className='me-2' id='ques12'
                                                            type="radio"
                                                            value='Poor'
                                                            checked={question3 === 'Poor'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        Poor
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques13'>

                                                        <input className='me-2' id='ques13'
                                                            type="radio"
                                                            value='Fair'
                                                            checked={question3 === 'Fair'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        Fair
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques14'>

                                                        <input className='me-2' id='ques14'
                                                            type="radio"
                                                            value='Good'
                                                            checked={question3 === 'Good'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        Good
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques15'>

                                                        <input className='me-2' id='ques15'
                                                            type="radio"
                                                            value='Excellent'
                                                            checked={question3 === 'Excellent'}
                                                            onChange={(e) => setQuestion3(e.target.value)}
                                                        />
                                                        Excellent
                                                    </label>
                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes("Rate your satisfaction with the timeliness of CSSD services.") && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">Rate your satisfaction with the timeliness of CSSD services.</div>
                                                            </>)}
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <h5 className='fs-6 fw-bolder'>5.	Satisfaction about availability of all services inside CSSD?</h5>

                                                </div>

                                                <div class="form-group col-md-12">
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques16'>

                                                        <input
                                                            type="radio" className='me-2' id='ques16'
                                                            value='Very Poor'
                                                            checked={question4 === 'Very Poor'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        Very Poor
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques17'>

                                                        <input className='me-2' id='ques17'
                                                            type="radio"
                                                            value='Poor'
                                                            checked={question4 === 'Poor'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        Poor
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques18'>

                                                        <input className='me-2' id='ques18'
                                                            type="radio"
                                                            value='Fair'
                                                            checked={question4 === 'Fair'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        Fair
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques19'>

                                                        <input className='me-2' id='ques19'
                                                            type="radio"
                                                            value='Good'
                                                            checked={question4 === 'Good'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        Good
                                                    </label>
                                                    <label className='form-check-label radio__label d-inline-block m-2 px-5 py-2' htmlFor='ques20'>

                                                        <input className='me-2' id='ques20'
                                                            type="radio"
                                                            value='Excellent'
                                                            checked={question4 === 'Excellent'}
                                                            onChange={(e) => setQuestion4(e.target.value)}
                                                        />
                                                        Excellent
                                                    </label>

                                                    <div class="form-group col-md-12 mb-0 mt-3">

                                                        {errors.includes('Rate your satisfaction with the availability of services in CSSD.') && (
                                                            <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">Rate your satisfaction with the availability of services in CSSD.</div>
                                                            </>)}
                                                    </div>
                                                </div>



                                                <div class="form-group col-md-12">
                                                    <label className={`fs-6 fw-bolder ${errors.includes('Please provide your recommendations and suggestions for improvement.') ? 'is-invalid' : ''}`}>5.	Suggestions & Comments</label>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <div class="form-group col-md-12">
                                                        <textarea class="form-control h-auto" value={question5} onChange={(e) => setQuestion5(e.target.value)}></textarea>
                                                        <div class="form-group col-md-12 mb-0 mt-3">

                                                            {errors.includes('Please provide your recommendations and suggestions for improvement.') && (
                                                                <><i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i> <div className="ivaild-error d-inline ms-2">Please provide your Comments and suggestions for improvement.</div>
                                                                </>)}
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-4">
                                                    <button className="btn btn-border btn-circle w-100" type='submit'><span>Submit</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="col-md-7 order-1 order-sm-2" style={{ backgroundImage: `url('/images/forms/diabetes-health-awareness-1.png')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                            <img src="/images/forms/diabetes-health-awareness-2.png" alt="patient survey 2024" className='d-block d-md-none img-fluid' style={{ backgroundSize: 'contain', backgroundColor: '#61882b', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }} />
                                        </div> */}
                                        <div class="col-sm-12 text-center">
                                            <Modal   {...props}
                                                size="lg"
                                                style={customStyles}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered show={show} onHide={handleClose} className="top-0 ">

                                                <Modal.Header closeButton>
                                                    <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                                                    <Modal.Title style={{ color: "#4ab242" }}>Your Message</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>
                                                    <div className="team-member text-center">

                                                        <div className="team-description pt-0">
                                                            <h5 className="my-5"><Link>{submitMsg}</Link></h5>
                                                            {/* <p className='lead'>{messageForm}</p> */}
                                                        </div>



                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                                        Close
                                                    </Button>

                                                </Modal.Footer>

                                            </Modal>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
        </div >
    </>
}
